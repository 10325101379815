import React, { useState, useEffect, useRef } from 'react';
import { db, doc, getDoc, addDoc, updateDoc, collection } from '../firebase';
import { useParams } from 'react-router-dom';
import CodeMirror from "react-codemirror";
import "codemirror/lib/codemirror.css";
import "codemirror/mode/markdown/markdown";

function StructuredForm() {
  const { docId } = useParams();
  const [content, setContent] = useState('');
  const editorRef = useRef(null);
  const [name, setName] = useState('');

  useEffect(() => {
    async function fetchData() {
      let docSnapshot;
      if (docId) {
        const docRef = doc(db, 'structured', docId);
        docSnapshot = await getDoc(docRef);
      } else {
        docSnapshot = { data: () => ({ content: '', name: '' }) };
      }
      setContent(docSnapshot.data().content);
      setName(docSnapshot.data().name);
      if (editorRef.current) {
        editorRef.current.getCodeMirror().setValue(docSnapshot.data().content);
      }
    }
    fetchData();
  }, [docId]);

  function handleChange(value) {
    setContent(value);
  }

  function handleFocus(editor) {
    console.log("Editor focused");
  }

  function handleNameChange(event) {
    setName(event.target.value);
  }

  async function handleSubmit(event) {
    event.preventDefault();
    const data = { name: name, content: content };
    if (docId) {
      const docRef = doc(db, 'structured', docId);
      await updateDoc(docRef, data);
    } else {
      const docRef = collection(db, 'structured');
      await addDoc(docRef, data);
    }
  }

  return (
    <div>
      <h1>Structured Content</h1>
      <form onSubmit={handleSubmit}>
        <label>
          Name:
          <input type="text" value={name} onChange={handleNameChange} />
        </label>
        <br />
        <CodeMirror
          ref={editorRef}
          value={content}
          options={{
            mode: "csv",
            theme: "material",
            lineNumbers: true,
          }}
          onBeforeChange={(editor, data, value) => {
            //setContent(editor);
          }}
          onChange={(value, editor) => {
            handleChange(value);
          }}
          onFocus={(editor) => {
            handleFocus(editor);
          }}
        />
        <button type="submit">Save</button>
      </form>
    </div>
  );
}

export default StructuredForm;


