import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { db, updateDoc, doc, getDoc } from '../firebase';
import { Link } from 'react-router-dom';

const Main = styled.div`
  margin-top: 20px;
`;

const TabContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`;

const Tab = styled(Link)`
  padding: 10px 20px;
  background-color: #ccc;
  border: 1px solid #aaa;
  border-radius: 4px;
  margin-right: 10px;
  text-decoration: none;
  color: #333;

  &:hover {
    background-color: #ddd;
  }

  &.active {
    background-color: #fff;
    border-bottom: 1px solid #fff;
  }
`;

function Layout({ children }) {
  const [versionNumber, setVersionNumber] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      // Fetch the current version number from Firebase
      const versionDocRef = doc(db, "system/version");
      const versionDocSnapshot = await getDoc(versionDocRef);
      const currentVersionNumber = versionDocSnapshot.data().number;
      setVersionNumber(currentVersionNumber);
    };
    fetchData();
  }, []);

  const handleUpdateVersion = async () => {
    const newVersionNumber = versionNumber + 1;
    const versionDocRef = doc(db, "system/version");
    await updateDoc(versionDocRef, { number: newVersionNumber });
    setVersionNumber(newVersionNumber);
  };

  return (
    <Main>
      {versionNumber && (
        <button onClick={handleUpdateVersion}>Update Version</button>
      )}

      <TabContainer>
        <Tab
          to={'/knowledge'}
          activeClassName="active"
        >
          Knowledge
        </Tab>
        <Tab
          to={'/structured'}
          activeClassName="active"
        >
          Structured
        </Tab>
      </TabContainer>

      {children}
    </Main>
  );
}

export default Layout;
