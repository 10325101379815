import React, { useState, useEffect } from 'react';
import { db, collection, getDocs, query } from '../firebase';
import { Link } from 'react-router-dom';
import { List } from './common';
import Layout from './layout';

function Knowledge() {
  const [docs, setDocs] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const q = query(collection(db, "knowledge"));
      const querySnapshot = await getDocs(q);
      setDocs(querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    };
    fetchData();
  }, []);

  

  return (
    <Layout>
      <Link to="/knowledge/new">New Document</Link>
      <List>
        {docs.map((doc) => (
          <li key={doc.id}><Link to={`/knowledge/${doc.id}`}>{doc.name}</Link></li>
        ))}
      </List>
    </Layout>
  );
}

export default Knowledge;
