import styled from 'styled-components';

export const List = styled.ul`
  list-style-type: none;
  margin:10px 0 0 0;
  padding:0;

  li {
    padding:5px;
    a {
      text-decoration:none;
      color:#336699;
    }
  }

  li:nth-child(odd) {
    background-color:#f0f0f0;
  }

`;