import { initializeApp } from "firebase/app";
import { getFirestore, query, collection, where, addDoc, getDocs, doc, getDoc, updateDoc, onSnapshot } from "firebase/firestore";
import { GoogleAuthProvider, getAuth, signInWithPopup } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyC-jYI-yNV9r5k        iEPqg4FRNhstMnMJvvJ4",
  authDomain: "proluxe-ai.firebaseapp.com",
  databaseURL: "https://proluxe-ai-default-rtdb.firebaseio.com",
  projectId: "proluxe-ai",
  storageBucket: "proluxe-ai.appspot.com",
  messagingSenderId: "114741055938",
  appId: "1:114741055938:web:67bcfd29288d5e4b23465b"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const auth = getAuth(); 
const googleProvider = new GoogleAuthProvider();

const signIn = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;
    const q = query(collection(db, "users"), where("uid", "==", user.uid));
    const docs = await getDocs(q);
    if (docs.docs.length === 0) {
      await addDoc(collection(db, "users"), {
        uid: user.uid,
        name: user.displayName,
        authProvider: "google",
        email: user.email,
      });
    }
  } catch (err) {
    console.error(err);
    alert(err.message);
  };
}


export { signIn, auth, db, collection, getDocs, query, doc, getDoc, updateDoc, addDoc, where, onSnapshot };