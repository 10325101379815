import React from 'react';
import Chat from './chat';
import Knowledge from './data_types/knowledge';
import KnowledgeForm from './data_types/knowledge_form';
import Structured from './data_types/structured';
import StructuredForm from './data_types/structured_form';
import Layout from './layout';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';


function App() {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route index path="/" element={<Chat />} />
          <Route path="knowledge" element={<Knowledge />} />
          <Route path="knowledge/new" element={<KnowledgeForm />} />
          <Route path="knowledge/:docId" element={<KnowledgeForm />} />
          <Route path="structured" element={<Structured />} />
          <Route path="structured/new" element={<StructuredForm />} />
          <Route path="structured/:docId" element={<StructuredForm />} />
        </Routes>
      </Layout>
    </Router>
    
  );
};


export default App;